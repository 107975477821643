<template>
  <Page full>
    <grid
      class="large-only"
      type="template"
      title="Templates"
      :multiple="true"
      :isMain="true"
      :order="[['company_id', 'desc']]"
      :permanentFilters="{
        template_type_id: '101'
      }"
    />
  </Page>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  name: '',
  data() {
    return {}
  },
  watch: {},
  computed: {},
  components: {
    Grid
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
